import React, { useEffect, useState } from "react";
import axios from "axios";
import "../../css/form.scss";
const ClubQuestion = ({ club, setClub }) => {
  const [selectAll, setSelectAll] = useState(false);
  const [clubs, setClubs] = useState([]);
  const [isChecked, setIsChecked] = useState(
    Array(clubs ? clubs.length : 0).fill(false)
  );

  const getClubsQues = async () => {
    try {
      let res = await axios.get(
        `${process.env.REACT_APP_BACKEND}/club-questions`
      );
      setClubs(res.data.data);
    } catch (err) {
      return err;
    }
  };
  useEffect(() => {
    getClubsQues();
  }, []);

  const checkboxHandler = (idx) => {
    const newArr = [...isChecked];
    if (newArr[idx]) {
      newArr[idx] = false;
    } else {
      newArr[idx] = true;
    }
    setIsChecked(newArr);
    let obj;
    for (let index = 0; index < newArr.length; index++) {
      if (newArr[index] === true) {
        obj = { clubQuestionId: clubs[index].id };
        setClubQuestionId(obj);
      }
    }
  };

  const setClubQuestionId = (objId) => {
    setClub([...club, objId]);
  };

  const selectAllHandler = (e) => {
    setSelectAll(e.target.checked);
    let all;

    all = clubs.map((item) => {
      return { clubQuestionId: item.id };
    });
    if (e.target.checked === true) {
      setClub(all);
    } else if (e.target.checked === false) {
      setClub([]);
      setIsChecked([]);
    }
  };

  return (
    <>
      <h6 className="text-uppercase my-3 text-center">
        Club Or Fraternal Associations
      </h6>

      <div className="control-group">
        <div className="check-all">
          <label className="control control-checkbox">
            Select All Options
            <input type="checkbox" onChange={selectAllHandler} />
            <div className="control_indicator"></div>
          </label>
        </div>
        <div className="custom-wrapper">
          {clubs.map((item, index) => (
            <div className="label-container" key={item.id}>
              <label className="control control-checkbox">
                {item.question}
                <input
                  type="checkbox"
                  checked={
                    selectAll ? "checked" : isChecked[index] ? "checked" : ""
                  }
                  onChange={() => {
                    checkboxHandler(index);
                  }}
                />
                <div className="control_indicator"></div>
              </label>
            </div>
          ))}
        </div>
      </div>
    </>
  );
};

export default ClubQuestion;
