import React, { useState } from "react";
import { startsWith } from "lodash";
import PhoneInput from "react-phone-input-2";
import "react-phone-input-2/lib/bootstrap.css";

export const PhoneNumber = ({
  setFieldValue,
  field,
  ifForm,
  setValues,
  values,
  label,
  ...props
}) => {
  //eslint-disable-next-line
  const [phone, setPhone] = useState("");
  //eslint-disable-next-line
  const [country, setCountry] = useState({ countryCode: "" });

  return (
    <div className="">
      <PhoneInput
        country="us"
        placeholder="+12345678900"
        inputClass="py-2 w-100"
        autoFormat={true}
        enableSearch={true}
        onChange={(val) => {
          if (val) {
            setPhone(`+${val.toString()}`);
          }
          setFieldValue(field.name, "+" + val);
        }}
        isValid={(inputNumber, country, countries) => {
          const validity = countries.some((country) => {
            return (
              startsWith(inputNumber, country.dialCode) ||
              startsWith(country.dialCode, inputNumber)
            );
          });
          return validity;
        }}
      />
    </div>
  );
};
