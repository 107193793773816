import React, { useEffect } from "react";
import "../../css/form.scss";
import hand from "../../images/icon/hand.png";
import ls from "localstorage-slim";

const Congrats = () => {
  return (
    <div className="container my-3 pt-2 px-4 wrapper d-flex align-items-center">
      <div className="container text-center ">
        <img src={hand} alt="hand" />
        <h2 className="mt-3 mb-2" style={{ fontWeight: "600" }}>
          Welcome
        </h2>
        <h5 style={{ color: "#767676", fontWeight: "500" }}>
          We're So Excited To Have So Many Doctors From{" "}
          {ls.get("u0nqwd", { decrypt: true })}, {""}
          On Our Platform!
        </h5>
      </div>
    </div>
  );
};

export default Congrats;
