import debit from "../../images/icon/debit-card.png";
import bank from "../../images/icon/bank.png";
import code from "../../images/icon/credit-code.png";
import "../../css/form.scss";
// Layout
import Footer from "../layout/footer";
import { Form } from "react-bootstrap";
import "../../css/join-now.css";

import JoinSection from "../elements/join-section";
import { useHistory } from "react-router-dom";
import CustomSpinner from "./spinner";
import React, { useState, useEffect } from "react";
import { toast } from "react-toastify";
import ls from "localstorage-slim";

import {
  PaymentElement,
  useStripe,
  useElements,
  // CardNumberElement,
  // CardExpiryElement,
  // CardCvcElement,
} from "@stripe/react-stripe-js";
// import axios from "axios";
// import { cryptoEncrypt } from "../helperFunctions/DataTransit";

// const options = {
//   style: {
//     base: {
//       fontSize: "16px",
//     },
//     invalid: {
//       color: "#9e2146",
//     },
//   },
// };

const SubPayment = () => {
  const [message, setMessage] = useState(null);
  const [isLoading, setIsLoading] = useState(false);

  // const [spin, setSpin] = useState(false);
  // const [holdersName, setHoldersName] = useState("");

  // const [paymentDone, setPaymentDone] = useState(undefined);
  // let emailLS = ls.get("e31@0", { decrypt: true });
  let amount = ls.get("amyte213", { decrypt: true });
  let discount = ls.get("di0c3t", { decrypt: true });
  let coupon = ls.get("c0r3w", { decrypt: true });
  // const history = useHistory();
  const stripe = useStripe();
  const elements = useElements();

  const clientSecret = ls.get("c!45dS", { decrypt: true });
  useEffect(() => {
    if (!stripe) {
      return;
    }

    // const clientSecret = new URLSearchParams(window.location.search).get(
    //   "payment_intent_client_secret"
    // );

    if (!clientSecret) {
      return;
    }

    stripe.retrievePaymentIntent(clientSecret).then(({ paymentIntent }) => {
      switch (paymentIntent.status) {
        case "succeeded":
          setMessage("Payment succeeded!");
          break;
        case "processing":
          setMessage("Your payment is processing.");
          break;
        case "requires_payment_method":
          setMessage("Your payment was not successful, please try again.");
          break;
        default:
          setMessage("Something went wrong.");
          break;
      }
    });
  }, [stripe]);

  const handleSubmit = async (e) => {
    e.preventDefault();

    if (!stripe || !elements) {
      // Stripe.js has not yet loaded.
      // Make sure to disable form submission until Stripe.js has loaded.
      return;
    }

    setIsLoading(true);
    const { error } = await stripe.confirmPayment({
      elements,
      confirmParams: {
        // Make sure to change this to your payment completion page
        // https://practicewisedds.com/on-boarding/two-factor-auth
        // http://pwdds-test.s3-website-us-east-1.amazonaws.com/on-boarding/two-factor-auth
        return_url: `${process.env.REACT_APP_STRIPE_REDIRECT_URL}`,
      },
    });

    if (error.type === "card_error" || error.type === "validation_error") {
      // setMessage(error.message);
      toast.error(error.message);
    } else {
      toast.error("An unexpected error occurred.");
      // setMessage("An unexpected error occurred.");
    }

    setIsLoading(false);
  };

  return (
    <>
      <div className="page-content bg-white">
        <JoinSection />
        <section className="">
          <div className="container bg-white shadow p-4 border p-2 rounded custom-bg">
            <h3 className="text-uppercase my-3 text-center">Payment</h3>
            <div
              className="container my-3 p-2 px-5 wrapper"
              // style={{ maxWidth: "820px" }}
            >
              {/* <div className="row d-flex justify-content-between align-items-end mb-1">
                <div className="col-md-3 col-sm-6 img-card text-center">
                  <img src={debit} alt="card" />
                  <h5 className="mt-3">Credit Card</h5>
                </div>
                <div className="col-md-3 col-sm-6 img-card text-center">
                  <img src={debit} alt="card" />
                  <h5 className="mt-3">Debit Card</h5>
                </div>
                <div className="col-md-3 col-sm-6 img-card text-center">
                  <img src={bank} alt="bank" />
                  <h5 className="mt-3">Bank</h5>
                </div>
                <div className="col-md-3 col-sm-6 img-card text-center">
                  <img src={code} alt="code" />
                  <h5 className="mt-3">Credit Code</h5>
                </div>
              </div> */}

              <div className="row">
                <div className="col-md-6 border-end mb-4">
                  <div className="container">
                    <h6>Payment Summary</h6>
                    <div className="row">
                      <div className="col-6">
                        <p
                          className=" text-muted "
                          style={{ fontSize: "0.9em" }}
                        >
                          Subscription Cost:
                        </p>
                      </div>
                      <div className="col-6">
                        <p
                          className="text-end  text-muted "
                          style={{ fontSize: "0.9em" }}
                        >
                          ${amount}
                        </p>
                      </div>
                    </div>

                    <div className="row border-bottom">
                      <div className="col-6">
                        <p
                          className=" text-muted"
                          style={{ fontSize: "0.9em" }}
                        >
                          Coupon Discount:
                        </p>
                      </div>
                      <div className="col-6">
                        <p
                          className="text-end"
                          style={{ fontSize: "0.9em", color: "#20C1FE" }}
                        >
                          {coupon ? coupon + "%" : "Not Applicable"}
                        </p>
                      </div>
                    </div>
                    <div className="row">
                      <div className="col-6">
                        <p className="" style={{ fontSize: "0.9em" }}>
                          Total Cost to Pay:
                        </p>
                      </div>
                      <div className="col-6">
                        <p
                          className="text-end fw-bold"
                          style={{ fontSize: "0.9em" }}
                        >
                          ${discount || amount}
                        </p>
                      </div>
                    </div>
                  </div>
                </div>
                <div className="col-md-6">
                  <form id="payment-form" onSubmit={handleSubmit}>
                    <PaymentElement id="payment-element" />
                    <div className="container d-flex justify-content-center mt-4">
                      <button
                        disabled={isLoading || !stripe || !elements}
                        id="submit"
                        className="btn btn-primary"
                      >
                        <span id="button-text">
                          {isLoading ? (
                            <div className="spinner" id="spinner"></div>
                          ) : (
                            "Pay now"
                          )}
                        </span>
                      </button>
                      {/* Show any error or success messages */}
                    </div>
                    {/* {message && (
                  <div id="payment-message" className="text-end">
                    {message}
                  </div>
                )} */}
                  </form>
                </div>
              </div>
            </div>
          </div>
        </section>
      </div>

      <Footer />
    </>
  );
};

export default SubPayment;
