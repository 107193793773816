import React, { useEffect, useState } from "react";
import "../../css/form.scss";
import { Elements } from "@stripe/react-stripe-js";
import { loadStripe } from "@stripe/stripe-js";
import SubPayment from "../elements/sub-payment";
import ls from "localstorage-slim";

import axios from "axios";
import { useHistory } from "react-router-dom";

const Payment = () => {
  const [stripeApiKey, setStripeApiKey] = useState("");
  const history = useHistory();
  if (ls.get("0a1r0t", { decrypt: true }) !== true) history.push("/");
  // load currently logged user instantly when user refresh the page
  useEffect(() => {
    // store.dispatch(load_user());​
    async function getStripeApiKey() {
      const { data } = await axios.get(
        `${process.env.REACT_APP_BACKEND}/payment/stripeapi`
      );
      setStripeApiKey(data.stripeApiKey);
    }
    getStripeApiKey();
  }, []);

  const clientSecret = ls.get("c!45dS", { decrypt: true });
  const appearance = {
    theme: "stripe",
  };
  const options = {
    clientSecret,
    appearance,
  };

  return (
    <>
      {stripeApiKey && (
        <Elements options={options} stripe={loadStripe(stripeApiKey)}>
          <SubPayment />
        </Elements>
      )}
    </>
  );
};

export default Payment;
