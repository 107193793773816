import React, { useState, useEffect } from "react";
import { Link, useHistory } from "react-router-dom";
import * as Yup from "yup";
import { toast } from "react-toastify";
import ls from "localstorage-slim";
import onBoarding2 from "../../images/banner/on-board2.png";
import Header from "../layout/header2";
import Footer from "../layout/footer";
import "../../css/join-now.css";
import { Modal, Form, Spinner } from "react-bootstrap";
import JoinSection from "../elements/join-section";
import {
  getAccessToken,
  initiateProofTransaction,
  // refreshAccessToken,
  // fetchTransactionResult,
} from "../services/authId";
import { useFormik } from "formik";
// import { cryptoEncrypt } from "../helperFunctions/DataTransit";
import { emailLookup } from "../services/pwdds";

const emailSchema = Yup.object().shape({
  email: Yup.string().email("Invalid email.").required("Email is required."),
});

const JoinNow = () => {
  const history = useHistory();
  const [show, setShow] = useState(false);
  const [spinner, setSpinner] = useState(false);
  const [accessToken, setAccessToken] = useState("");
  // const [refreshToken, setRefreshToken] = useState("");
  const [operationId, setOperationId] = useState("");
  const [docType, setDocType] = useState("");
  const [policyAccepted, setPolicyAccepted] = useState(false);

  const getAccessTokenHandler = async () => {
    try {
      let res = await getAccessToken();
      setAccessToken(res.data.AccessToken);
      // setRefreshToken(res.data.RefreshToken);
    } catch (error) {
      return error;
    }
  };

  useEffect(() => {
    getAccessTokenHandler();
  }, [docType]);

  const formik = useFormik({
    initialValues: {
      email: "",
    },
    validationSchema: emailSchema,
    onSubmit: async (values) => {
      try {
        let res = await emailLookup(values.email);
        setSpinner(true);
        toast.info(res.data.message);
        setSpinner(false);
      } catch (error) {
        setSpinner(true);
        if (error.response.status === 404) {
          let operationId = await initiateProofTransaction(
            accessToken,
            docType,
            values.email
          );

          ls.set("e31@0", values.email, { encrypt: true });
          ls.set("0dgfd@", operationId, { encrypt: true });
          setOperationId(operationId);
        }

        setSpinner(false);

        setTimeout(() => {
          console.clear();
        }, 1200);
      }
    },
  });

  useEffect(() => {
    if (operationId) {
      setTimeout(() => {
        history.push("/");
      }, 180000);
    }
  }, [operationId]);

  // const continueBtnHandler = async () => {
  //   setSpinner(true);

  //   let res = await refreshAccessToken(refreshToken);
  //   setAccessToken(res.data.AccessToken);
  //   setRefreshToken(res.data.RefreshToken);
  //   ls.set("@66$$7", res.data.AccessToken, { encrypt: true });
  //   await fetchTransactionResult(res.data.AccessToken, operationId, history);
  //   setSpinner(false);
  // };

  return (
    <>
      {!operationId && !show ? <Header /> : null}

      <Modal
        size="md"
        show={show}
        aria-labelledby="example-modal-sizes-title-lg"
        centered
        backdrop={true}
      >
        <div className="p-4">
          <form onSubmit={formik.handleSubmit}>
            {!operationId && (
              <>
                <h6>Enter Your Email to Join</h6>
                <Form.Group
                  className="mb-3"
                  controlId="exampleForm.ControlInput1"
                >
                  <Form.Control
                    className="border form-control my-1 px-3 form-control"
                    type="email"
                    name="email"
                    placeholder="Email Address"
                    onChange={formik.handleChange}
                    value={formik.values.email}
                    autoFocus
                  />
                  {formik.touched.email && formik.errors.email && (
                    <div className="fv-plugins-message-container">
                      <div className="fv-help-block text-danger">
                        {formik.errors.email}
                      </div>
                    </div>
                  )}
                </Form.Group>
                <div className="px-1 mb-3">
                  <div className="form-check">
                    <input
                      className="form-check-input"
                      type="checkbox"
                      checked={policyAccepted}
                      id="flexCheckDefault"
                      onChange={(e) => setPolicyAccepted(e.target.checked)}
                    />
                    <label
                      className="form-check-label fw-semibold fst-italic"
                      htmlFor="flexCheckDefault"
                    >
                      By creating an account, you confirm you have read,
                      understand and accept{" "}
                      <Link to="/terms">Dwolla's Terms of Service</Link>, and{" "}
                      <Link to="/privacy-policy">Privacy Policy.</Link>
                    </label>
                  </div>
                </div>

                <div className="text-center">
                  <button
                    className="btn btn-primary  mx-2"
                    type="button"
                    onClick={() => setShow(false)}
                  >
                    Cancel
                  </button>
                  {policyAccepted && (
                    <button className="btn btn-secondary mx-2" type="submit">
                      {spinner ? (
                        <Spinner animation="border" variant="info" />
                      ) : (
                        "Submit"
                      )}
                    </button>
                  )}
                </div>
              </>
            )}
          </form>

          {operationId && (
            <>
              <h6 className="text-center p-3">
                "We sent a link to your email, kindly verify your verification
                and complete your profile.
                {/* Then click continue to complete your profile." */}
              </h6>
              {/* <div className="d-flex justify-content-center">
                <button
                  className="btn btn-secondary mx-2"
                  type="button"
                  onClick={continueBtnHandler}
                >
                  {spinner ? (
                    <Spinner animation="border" variant="info" />
                  ) : (
                    "Continue"
                  )}
                </button>
              </div> */}
            </>
          )}
        </div>
      </Modal>

      <div className="page-content bg-white">
        <JoinSection />

        <section className="">
          <div className="banner-img text-center my-3">
            <img src={onBoarding2} alt="" />
          </div>
          <div className="container bg-white shadow p-4 border rounded text-center custom-bg">
            <h6 className="text-uppercase my-3">
              Select a document to verify your Identification
            </h6>
            <div className="row">
              <div className="col-lg-6 col-md-6 my-30 ">
                <div
                  className="feature-container feature-bx4 document-types feature4 "
                  onClick={() => {
                    setShow(true);
                    setDocType("2");
                  }}
                >
                  <div className="icon-content">
                    <h5 className="ttr-title">US Driver License Adult</h5>
                  </div>
                </div>
              </div>
              <div className="col-lg-6 col-md-6 my-30 ">
                <div
                  className="feature-container feature-bx4 document-types feature3 "
                  onClick={() => {
                    setShow(true);
                    setDocType("5");
                  }}
                >
                  <div className="icon-content">
                    <h5 className="ttr-title">Passport</h5>
                  </div>
                </div>
              </div>
              <div className="col-lg-6 col-md-6 my-30 ">
                <div
                  className="feature-container feature-bx4 document-types feature2 "
                  onClick={() => {
                    setShow(true);
                    setDocType("1001");
                  }}
                >
                  <div className="icon-content">
                    <h5 className="ttr-title">ID One Sided</h5>
                  </div>
                </div>
              </div>
              <div className="col-lg-6 col-md-6 my-30 ">
                <div
                  className="feature-container feature-bx4 document-types  "
                  onClick={() => {
                    setShow(true);
                    setDocType("1002");
                  }}
                >
                  <div className="icon-content">
                    <h5 className="ttr-title">ID Two Sided</h5>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </section>
      </div>

      <Footer />
    </>
  );
};

export default JoinNow;
