import axios from "axios";
// import { toast } from "react-toastify";
// import ls from "localstorage-slim";

export const getAccessToken = async () => {
  return await axios.post(
    `${process.env.REACT_APP_IDPURL}/auth/token`,
    {},
    {
      auth: {
        username: process.env.REACT_APP_ADMIN_USERNAME,
        password: process.env.REACT_APP_ADMIN_PASSWORD,
      },
    }
  );
};

// export const getAccessToken = async (setAccessToken, setRefreshToken) => {
//   try {
//     const res = await axios.post(
//       `${process.env.REACT_APP_IDPURL}/auth/token`,
//       {},
//       {
//         auth: {
//           username: process.env.REACT_APP_ADMIN_USERNAME,
//           password: process.env.REACT_APP_ADMIN_PASSWORD,
//         },
//       }
//     );
//     setAccessToken(res.data.AccessToken);
//     setRefreshToken(res.data.RefreshToken);
//     return res;
//   } catch (error) {
//     return error;
//   }
// };

export const refreshAccessToken = async (refreshToken) => {
  try {
    const res = axios.post(
      `${process.env.REACT_APP_IDPURL}/auth/refresh`,
      refreshToken,
      {
        headers: {
          "Content-Type": "application/json",
        },
      }
    );

    return res;
  } catch (error) {
    return error;
  }
};

export const initiateProofTransaction = async (accessToken, docType, email) => {
  try {
    const res = await axios.post(
      `${process.env.REACT_APP_BASEURL}/foreignOperations/documents`,
      {
        DocumentTypes: [docType],
        TimeoutSec: 3600,
        PhoneNumber: null,
        Email: email,
        TransportType: 2,
        IntroductionText: null,
      },
      {
        headers: {
          Authorization: "Bearer " + accessToken,
        },
      }
    );
    return res.data.OperationId;
  } catch (error) {
    return error;
  }
};

//   setOperationId(res.data.OperationId);
//   localStorage.setItem("email", email);
//   setIsSubmit(true);
// };

// //fetch TempId from authId for user document details
export const fetchTransactionResult = async (
  accessToken,
  operationId,
  history
) => {
  try {
    let res = await axios.get(
      `${process.env.REACT_APP_BASEURL}/foreignOperations/documents/${operationId}`,
      {
        headers: {
          Authorization: "Bearer " + accessToken,
        },
      }
    );

    return res.data.TempId;
  } catch (error) {
    history.push("/");
    return error;
  }
};

// export const fetchTransactionResult = async (
//   accessToken,
//   operationId,
//   history
// ) => {
//   try {
//     let res = await axios.get(
//       `${process.env.REACT_APP_BASEURL}/foreignOperations/documents/${operationId}`,
//       {
//         headers: {
//           Authorization: "Bearer " + accessToken,
//         },
//       }
//     );

//     if (res.data.TempId !== null) {
//       ls.set("t79i1d", res.data.TempId, { encrypt: true });
//       history.push("/on-boarding/user-details");
//     } else {
//       toast.warn("You are not verified.");
//     }
//     // console.log("res.data.TempId", res.data.TempId);
//     return res.data.TempId;
//   } catch (error) {
//     toast.warn("Something Went Wrong.");
//     return error;
//   }
// };

export const getUserDataFromAuthId = async (accessToken, id) => {
  try {
    const res = await axios.get(
      `${process.env.REACT_APP_BASEURL}/operations/verifications/${id}`,
      {
        headers: {
          Authorization: "Bearer " + accessToken,
        },
      }
    );
    return res.data.Data.Document.Data;
  } catch (error) {
    return error;
  }
};
