import React from "react";
import "../../css/form.scss";
const EmployeeStatus = ({ employeeStatus, setEmployeeStatus }) => {
  const radioHandler = (e) => {
    setEmployeeStatus(e.target.value);
  };

  return (
    <>
      <h4 className="text-center " style={{ fontWeight: 600 }}>
        Employment Status
      </h4>

      <div className="control-group">
        <div className="custom-wrapper2">
          {/* <div className="check-all">
          <label className="control control-checkbox">
            Select All Options
            <input type="checkbox" onChange={selectAllHandler} />
            <div className="control_indicator"></div>
          </label> */}

          <div className="label-container">
            <div className="radio">
              <input
                id="radio-1"
                name="radio"
                type="radio"
                onChange={radioHandler}
                value="Student"
              />
              <label htmlFor="radio-1" className="radio-label">
                Student
              </label>
            </div>
          </div>
          <div className="label-container">
            <div className="radio">
              <input
                id="radio-2"
                name="radio"
                type="radio"
                onChange={radioHandler}
                value="Intern"
              />
              <label htmlFor="radio-2" className="radio-label">
                Intern
              </label>
            </div>
          </div>
          <div className="label-container">
            <div className="radio">
              <input
                id="radio-3"
                name="radio"
                type="radio"
                onChange={radioHandler}
                value="Associate In Private Practice"
              />
              <label htmlFor="radio-3" className="radio-label">
                Associate In Private Practice
              </label>
            </div>
          </div>
          <div className="label-container">
            <div className="radio">
              <input
                id="radio-4"
                name="radio"
                type="radio"
                onChange={radioHandler}
                value="Operating My Own Private Practice"
              />
              <label htmlFor="radio-4" className="radio-label">
                Operating My Own Private Practice
              </label>
            </div>
          </div>
          <div className="label-container">
            <div className="radio">
              <input
                id="radio-5"
                name="radio"
                type="radio"
                onChange={radioHandler}
                value="Partner In A Group Private Practice"
              />
              <label htmlFor="radio-5" className="radio-label">
                Partner In A Group Private Practice
              </label>
            </div>
          </div>
          <div className="label-container">
            <div className="radio">
              <input
                id="radio-6"
                name="radio"
                type="radio"
                onChange={radioHandler}
                value="University Professor And Operate My Own Private Practice"
              />

              <label htmlFor="radio-6" className="radio-label">
                University Professor And Operate My Own Private Practice
              </label>
            </div>
          </div>
          <div className="label-container">
            <div className="radio">
              <input
                id="radio-7"
                name="radio"
                type="radio"
                onChange={radioHandler}
                value="University Professor"
              />
              <label htmlFor="radio-7" className="radio-label">
                University Professor
              </label>
            </div>
          </div>
          <div className="label-container">
            <div className="radio">
              <input
                id="radio-8"
                name="radio"
                type="radio"
                onChange={radioHandler}
                value="Retired From Private Practice"
              />
              <label htmlFor="radio-8" className="radio-label">
                Retired From Private Practice
              </label>
            </div>
          </div>
        </div>
      </div>
    </>
  );
};

export default EmployeeStatus;
