import React, { useEffect, useState } from "react";
import ls from "localstorage-slim";
// Layout
import Footer from "../layout/footer";

import "../../css/join-now.css";
import JoinSection from "../elements/join-section";

import { useHistory } from "react-router-dom";
import axios from "axios";
import { cryptoEncrypt } from "../helperFunctions/DataTransit";
import { toast } from "react-toastify";
import { Spinner } from "react-bootstrap";

const Pricing = () => {
  // const [customerId, setCustomerId] = useState("");
  const [couponState, setCouponState] = useState({});
  const [spinner, setSpinner] = useState({
    spin1: false,
    spin2: false,
    spin3: false,
  });
  const [couponCode, setCouponCode] = useState("");
  const history = useHistory();
  if (ls.get("0a1r0t", { decrypt: true }) !== true) history.push("/");

  let fname = ls.get("f832$n", { decrypt: true });
  let lname = ls.get("lgh#pw1", { decrypt: true });

  let fullName = `${lname} ${fname}`;

  let email = ls.get("e31@0", { decrypt: true });

  const createCustomerOnStripe = async () => {
    let encData = cryptoEncrypt({
      customerEmail: email,
      customerName: fullName,
    });

    try {
      let res = await axios.post(
        `${process.env.REACT_APP_BACKEND}/payment/stripe/create/customer`,
        {
          data: encData,
        }
      );

      return res.data.customer.id;
    } catch (error) {
      return error;
    }
  };

  const paymentSubscription = async (postObj) => {
    let encData = cryptoEncrypt(postObj);
    try {
      let res = await axios.post(
        `${process.env.REACT_APP_BACKEND}/payment/stripe/create/subscription`,
        {
          data: encData,
        }
      );
      // console.log(res.data.client_secret);
      ls.set("c!45dS", res.data.client_secret, { encrypt: true });
      setSpinner(false);
      setSpinner({ ...spinner, spin1: false, spin2: false, spin3: false });
      setTimeout(() => {
        history.push("/on-boarding/payment");
      }, 1000);
    } catch (error) {
      setSpinner({ ...spinner, spin1: false, spin2: false, spin3: false });
      return error;
    }
  };

  const coupon = async () => {
    try {
      let res = await axios.get(
        `${process.env.REACT_APP_BACKEND}/coupon/by?name=pwdds80%off`
      );
      // console.log("coupon", res.data.data);
      setCouponState({ ...res.data.data });
    } catch (error) {
      toast.error(error.response.data.message);
      return error;
    }
  };

  const paymentHandler = async (amount) => {
    let customerId = await createCustomerOnStripe();
    let obj = {};
    if (amount === 1700) {
      setSpinner({ ...spinner, spin2: true });
      ls.set("amyte213", amount, { encrypt: true });
      let priceId = process.env.REACT_APP_STRIPE_SUBSCRIPTION_PRICE_ID;
      obj = {
        stripeCustomerId: customerId,
        priceId,
        customerEmail: null,
        couponId: null,
        originalPrice: null,
      };
      await paymentSubscription(obj);
    } else if (amount === 5000) {
      setSpinner({ ...spinner, spin1: true });
      let discount = amount - (couponState.amount / 100) * amount;

      ls.set("c0r3w", couponState.amount, { encrypt: true });
      ls.set("di0c3t", discount, { encrypt: true });
      ls.set("amyte213", amount, { encrypt: true });
      obj = {
        stripeCustomerId: customerId,
        priceId: null,
        customerEmail: email,
        couponId: couponState.id,
        originalPrice: amount,
      };

      await paymentSubscription(obj);
    } else if (amount === 5) {
      setSpinner({ ...spinner, spin3: true });
      let discount = amount - (couponState.amount / 100) * amount;
      ls.set("c0r3w", couponState.amount, { encrypt: true });
      ls.set("di0c3t", discount, { encrypt: true });
      ls.set("amyte213", amount, { encrypt: true });
      obj = {
        stripeCustomerId: customerId,
        priceId: null,
        customerEmail: email,
        couponId: couponState.id,
        originalPrice: amount,
      };

      await paymentSubscription(obj);
    }
  };

  const applyCouponHandler = async () => {
    // await coupon();
    if (couponState.name === couponCode) {
      await paymentHandler(5000);
    } else {
      toast.error("Coupon Code is not Valid!");
    }
  };
  useEffect(() => {
    coupon();
    ls.remove("c0r3w");
    ls.remove("di0c3t");
    ls.remove("amyte213");
  }, []);

  return (
    <>
      <div className="page-content bg-white">
        <JoinSection />

        <section className="">
          <div className="container bg-white shadow p-4 border rounded custom-bg">
            <h3 className="text-uppercase my-3 text-center">Pricing</h3>
            <div className="container my-4">
              {/* <div
                className="alert alert-primary"
                role="alert"
                style={{ fontSize: ".8rem !important" }}
              >
                Big Discount on Special Lifetime Membership, Apply Coupon Code
                <span className="fw-bold"> "{couponState.name}"</span> and Save
                Upto 80% Amount.
              </div> */}
              <div className="row row-cols-1 row-cols-md-2 mb-3 text-center">
                {/* <div className="col-lg-6 col-md-12">
                  <div className="card mb-4 rounded-3 shadow-sm border-primary">
                    <div className="card-header py-3 text-white bg-primary border-primary">
                      <h4
                        className="my-0 fw-normal"
                        style={{ fontSize: ".9rem" }}
                      >
                        Production Test Offer
                      </h4>
                    </div>
                    <div className="card-body">
                      <h1 className="card-title pricing-card-title">$5</h1>
                      <ul className="list-unstyled mt-3 mb-4">
                        <li>Reduce Debt Quickly</li>
                        <li>Don't Overpay In Interest</li>
                        <li>Protect Your Assets</li>
                        <li style={{ color: "white" }}>some text</li>
                      </ul>

                      <button
                        type="button"
                        className="py-2 px-4 btn btn-primary"
                        style={{ fontSize: "1.5rem" }}
                        onClick={() => paymentHandler(5)}
                      >
                        {spinner.spin3 ? (
                          <Spinner animation="border" variant="info" />
                        ) : (
                          "Pay"
                        )}
                      </button>
                    </div>
                  </div>
                </div> */}
                <div className="col-lg-6 col-md-12">
                  <div className="card mb-4 rounded-3 shadow-sm">
                    <div className="card-header py-3 text-white bg-primary border-primary">
                      <h4
                        className="my-0 fw-normal"
                        style={{ fontSize: ".9rem" }}
                      >
                        Special Lifetime Membership (Limited Offer)
                      </h4>
                    </div>
                    <div className="card-body">
                      <div>
                        <h1 className="card-title pricing-card-title d-inline mx-1">
                          $5000
                          {/* ${5000 - (couponState.amount / 100) * 5000} */}
                        </h1>
                        {/* <span>
                          <s>$5000</s> {couponState.amount}% off on Coupon
                        </span> */}
                      </div>

                      <ul className="list-unstyled mt-3 mb-4">
                        <li>Reduce Debt Quickly</li>
                        <li>Don't Overpay In Interest</li>
                        <li>Protect Your Assets</li>
                        <li>Boost You Credit Score</li>
                      </ul>
                      <div className="my-2 row d-flex justify-content-center">
                        <div className="col-md-8 col-sm-8 col-lg-8 p-0">
                          <input
                            type="text"
                            className="form-control border p-2 d-inline"
                            placeholder="Enter Coupon Code"
                            value={couponCode}
                            onChange={(e) => setCouponCode(e.target.value)}
                          />
                        </div>

                        {/* <div className="col-md-2 col-lg-2 col-sm-2 p-0">
                          <button className="btn btn-primary p-2" type="button">
                            Apply
                          </button>
                        </div> */}
                      </div>

                      {couponCode.length > 0 ? (
                        <button
                          type="button"
                          className="py-2 px-4 btn btn-primary"
                          style={{ fontSize: "1.5rem" }}
                          onClick={applyCouponHandler}
                        >
                          {spinner.spin1 ? (
                            <Spinner animation="border" variant="info" />
                          ) : (
                            "Apply Coupon"
                          )}
                        </button>
                      ) : (
                        <button
                          type="button"
                          className="py-2 px-4 btn btn-primary"
                          style={{ fontSize: "1.5rem" }}
                          onClick={() => paymentHandler(5000)}
                        >
                          {spinner.spin1 ? (
                            <Spinner animation="border" variant="info" />
                          ) : (
                            "Pay"
                          )}
                        </button>
                      )}
                    </div>
                  </div>
                </div>
                <div className="col-lg-6 col-md-12">
                  <div className="card mb-4 rounded-3 shadow-sm border-primary">
                    <div className="card-header py-3 text-white bg-primary border-primary">
                      <h4
                        className="my-0 fw-normal"
                        style={{ fontSize: ".9rem" }}
                      >
                        3 Months (Limited Time Offer)
                      </h4>
                    </div>
                    <div className="card-body">
                      <h1 className="card-title pricing-card-title">$1700</h1>
                      <ul className="list-unstyled mt-3 mb-4">
                        <li>Reduce Debt Quickly</li>
                        <li>Don't Overpay In Interest</li>
                        <li>Protect Your Assets</li>
                        <li>
                          <br />
                        </li>
                        <li>
                          <br />
                        </li>
                        <li>
                          <br />
                        </li>
                        {/* <li style={{ color: "white" }}>some text</li> */}
                      </ul>

                      <button
                        type="button"
                        className="py-2 px-4 btn btn-primary"
                        style={{ fontSize: "1.5rem" }}
                        onClick={() => paymentHandler(1700)}
                      >
                        {spinner.spin2 ? (
                          <Spinner animation="border" variant="info" />
                        ) : (
                          "Pay"
                        )}
                      </button>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </section>

        <div className="container w-50 mt-2">
          {/* <div
            className={`d-flex ${
              btnNextAndBack === 0
                ? "justify-content-end"
                : btnNextAndBack === 4
                ? "justify-content-center"
                : "justify-content-between"
            }`}
          >
            {btnNextAndBack === 0 ? null : btnNextAndBack === 4 ? null : (
              <button
                type="button"
                className="btn btn-secondary shadow"
                onClick={() => setBtnNextAndBack(btnNextAndBack - 1)}
              >
                Back
              </button>
            )}
            {btnNextAndBack >= 3 ? (
              <button
                type="button"
                className="btn btn-primary shadow"
                onClick={() => {
                  if (btnNextAndBack === 5) {
                    history.push("/pricing");
                    alert("rout");
                  } else {
                    setBtnNextAndBack(btnNextAndBack + 1);
                  }
                }}
              >
                Continue
              </button>
            ) : (
              <button
                type="button"
                className="btn btn-primary shadow"
                onClick={() => setBtnNextAndBack(btnNextAndBack + 1)}
              >
                Next
              </button>
            )}
          </div> */}
        </div>
      </div>

      <Footer />
    </>
  );
};

export default Pricing;
