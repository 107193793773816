import React, { useState, useEffect } from "react";
import { Link, useHistory } from "react-router-dom";
import { Formik, Form as FormikForm, Field } from "formik";
import * as Yup from "yup";
import axios from "axios";

import Footer from "../layout/footer";
import ls from "localstorage-slim";
import onBoarding3 from "../../images/banner/on-board3.png";
import { toast } from "react-toastify";

import partyEmoji from "../../images/icon/partyemoji.png";
import "../../css/join-now.css";
import { Modal, Form, Spinner } from "react-bootstrap";
import JoinSection from "../elements/join-section";
import {
  fetchTransactionResult,
  getAccessToken,
  getUserDataFromAuthId,
} from "../services/authId";
import { PhoneNumber } from "../elements/PhoneNumber";
import CustomSpinner from "../elements/spinner";
import { cryptoEncrypt } from "../helperFunctions/DataTransit";
import { checkUniversity, getUniversities } from "../services/pwdds";

const detailsForm = Yup.object().shape({
  address: Yup.string().required("Field is Required"),
  mobile: Yup.string().required("Field is Required"),
  graduationDate: Yup.string().required("Field is Required"),
  dentalUniversityId: Yup.string().required("Field is Required"),
  // password: Yup.string()
  //   .required("Please Enter your password")
  //   .min(8, "Must Contain only 8 Characters.")
  //   .max(8, "Must Contain only 8 Characters.")
  //   .matches(
  //     /^(?=.*[a-z])(?=.*[A-Z])(?=.*[0-9])(?=.*[!@#\$%\^&\*])(?=.{8,})/,
  //     "Must Contain only 8 Characters, One Uppercase, One Lowercase, One Number and One Special Case Character."
  //   ),
  // confirmpassword: Yup.string()
  //   .oneOf([Yup.ref("password"), null], "Passwords must be same.")
  //   .required("Field is Required"),
});

const UserDetails = () => {
  const [show, setShow] = useState(false);
  const [universitiesFromApi, setUniversitiesFromApi] = useState([]);
  const [userData, setUserData] = useState([]);
  const [firstPersonInUniversity, setFirstPersonInUniversity] = useState("");
  const [passwordState, setPasswordState] = useState("");
  const [confirmPasswordState, setConfirmPasswordState] = useState("");
  const [passwordType, setPasswordType] = useState("password");

  const history = useHistory();

  const togglePassword = () => {
    if (passwordType === "password") {
      setPasswordType("text");
      return;
    }
    setPasswordType("password");
  };

  const initialComponentData = async () => {
    // let tempId = ls.get("t79i1d", { decrypt: true });
    let operationId = ls.get("0dgfd@", { decrypt: true });
    let token, tempId;
    // let token = ls.get("@66$$7", { decrypt: true });
    try {
      let res = await getAccessToken();
      token = res.data.AccessToken;
      tempId = await fetchTransactionResult(token, operationId, history);
    } catch (error) {
      return error;
    }

    try {
      let res = await getUniversities();
      setUniversitiesFromApi(res.data.data);
    } catch (error) {
      return error;
    }

    let data = await getUserDataFromAuthId(token, tempId);
    setUserData(data);
  };

  useEffect(() => {
    initialComponentData();
  }, []);

  const postGeneralDetails = async (data) => {
    let encData = cryptoEncrypt(data);
    try {
      let res = await axios.post(`${process.env.REACT_APP_BACKEND}/user`, {
        data: encData,
      });
      // localStorage.setItem("id", res.data.data.id);
      ls.set("11D", res.data.data.id, { encrypt: true });
      // history.push("/on-boarding/questionnaire");
      setShow(true);
      // toast.success(
      //   "Congratulations, your account has been successfully created."
      // );
      // toast.success(
      //   "Congratulations, your account has been successfully created."
      // );
      ls.set("0a1r0t", true, { encrypt: true });
    } catch (error) {
      toast.error(error.response.data.message);
      // toast.error("Something went wrong!");
      // setShow(false);
      return error;
    }
  };

  const initValues =
    userData.length > 0
      ? {
          fname:
            userData.find((ele) => ele.Key === "PrimaryIdentifier" && ele.Value)
              ?.Value || "",
          lname:
            userData.find(
              (ele) => ele.Key === "SecondaryIdentifier" && ele.Value
            )?.Value || "",
          email: ls.get("e31@0", { decrypt: true }),
          age:
            userData.find((ele) => ele.Key === "Age" && ele.Value)?.Value || "",
          issueState:
            userData.find((ele) => ele.Key === "IssuingStateName" && ele.Value)
              ?.Value || "",

          expiryDate:
            userData.find((ele) => ele.Key === "DateOfExpiry" && ele.Value)
              ?.Value || "",
          documentNumber:
            userData.find((ele) => ele.Key === "DocumentNumber" && ele.Value)
              ?.Value || "",
          dob:
            userData.find((ele) => ele.Key === "DateOfBirth" && ele.Value)
              ?.Value || "",
          address:
            userData.find((ele) => ele.Key === "Address" && ele.Value)?.Value ||
            "",
          issuingAuthority: "Empty String",
          mobile: "",
          dentalUniversityId: "",
          gender:
            userData.find((ele) => ele.Key === "Sex" && ele.Value)?.Value ===
            "M"
              ? "male"
              : "female",
          graduationDate: "",
          emoji: "emoji",
          password: "",
          // confirmpassword: "",
          isPolicyAccepted: true,
        }
      : {
          fname: "",
          lname: "",
          email: "",
          age: "",
          issueState: "",
          expiryDate: "",
          documentNumber: "",
          dob: "",
          address: "",
          issuingAuthority: "",
          mobile: "",
          dentalUniversityId: "",
          gender: "",
          graduationDate: "",
          emoji: "",
          password: "",
          // confirmpassword: "",
          isPolicyAccepted: true,
        };

  return (
    <>
      {/* <Header /> */}
      <Modal
        //   size="lg"
        show={show}
        aria-labelledby="example-modal-sizes-title-lg"
        centered
        backdrop={true}
        contentClassName="custom-modal text-center"
      >
        {/* <Modal.Body> */}
        <div className="d-flex justify-content-center my-3">
          <img src={partyEmoji} alt="partyEmoji" />
        </div>
        <h3>Congratulations</h3>
        <p>{firstPersonInUniversity}</p>
        {/* <h4>Kindly check your email for further process!</h4> */}
        <h6>Click on continue for further process.</h6>
        <div className="mt-4">
          <Link to="/on-boarding/questionnaire" className="btn btn-primary">
            Continue
          </Link>
        </div>
      </Modal>
      <div className="page-content bg-white">
        <JoinSection />

        <section className="">
          <div className="banner-img text-center my-3">
            <img src={onBoarding3} alt="" />
          </div>
          <div className="container bg-white shadow p-4 border rounded custom-bg">
            <h6 className="text-uppercase my-3 text-center">General Detail</h6>

            {userData.length === 0 ? (
              <CustomSpinner />
            ) : (
              <Formik
                enableReinitialize
                initialValues={initValues}
                validationSchema={detailsForm}
                onSubmit={async (values) => {
                  const valuesUpdate = { ...values };
                  // localStorage.setItem("canRoute", true);
                  const uniId = universitiesFromApi.find(
                    (item) => item.id === valuesUpdate.dentalUniversityId
                  );
                  const res = await checkUniversity(uniId.id);
                  setFirstPersonInUniversity(res.data.message);
                  // localStorage.setItem("uniId", uniId.id);
                  // localStorage.setItem("uniName", res.data.university);
                  ls.set("5ni11d", uniId.id, { encrypt: true });
                  ls.set("u0nqwd", res.data.university, { encrypt: true });
                  ls.set("f832$n", valuesUpdate.fname, { encrypt: true });
                  ls.set("lgh#pw1", valuesUpdate.lname, { encrypt: true });

                  delete valuesUpdate.issueState;
                  // delete valuesUpdate.confirmpassword;
                  valuesUpdate.password = passwordState;
                  // console.log("values,", valuesUpdate);

                  if (
                    passwordState === confirmPasswordState &&
                    /^(?=.*[a-z])(?=.*[A-Z])(?=.*[0-9])(?=.*[!@#\$%\^&\*])(?=.{8,})/.test(
                      passwordState
                    )
                  ) {
                    // console.log(valuesUpdate);
                    await postGeneralDetails(valuesUpdate);
                  }

                  // setShow(true);
                  // setUpdatedValues(valuesUpdate);
                  // setAvatarScreen(true);
                }}
              >
                {({ setFieldValue }) => (
                  <FormikForm>
                    <div className="form-container">
                      <div className="row gx-5">
                        {/* firstName */}
                        <div className="col-md-4">
                          <Form.Group className="mb-3" controlId="firstname">
                            <Form.Label>Surname</Form.Label>

                            <Field name="fname">
                              {({ field, form, meta }) => (
                                <>
                                  <Form.Control
                                    disabled
                                    type="text"
                                    {...field}
                                    className="p-2"
                                  />

                                  {meta.touched && meta.error && (
                                    <div
                                      className="error"
                                      style={{
                                        color: "red",
                                        fontSize: "0.7rem",
                                      }}
                                    >
                                      {meta.error}
                                    </div>
                                  )}
                                </>
                              )}
                            </Field>
                          </Form.Group>
                        </div>

                        {/* lastName */}
                        <div className="col-md-4">
                          <Form.Group className="mb-3" controlId="lastname">
                            <Form.Label>Given Name</Form.Label>
                            <Field name="lname">
                              {({ field, form, meta }) => (
                                <>
                                  <Form.Control
                                    disabled
                                    type="text"
                                    {...field}
                                    className="p-2"
                                  />
                                  {meta.touched && meta.error && (
                                    <div
                                      className="error"
                                      style={{
                                        color: "red",
                                        fontSize: "0.7rem",
                                      }}
                                    >
                                      {meta.error}
                                    </div>
                                  )}
                                </>
                              )}
                            </Field>
                          </Form.Group>
                        </div>

                        {/* Email */}
                        <div className="col-md-4">
                          <Form.Group className="mb-3" controlId="email">
                            <Form.Label>Email Address</Form.Label>
                            <Field name="email">
                              {({ field, form, meta }) => (
                                <>
                                  <Form.Control
                                    disabled
                                    type="email"
                                    {...field}
                                    className="p-2"
                                  />
                                  {meta.touched && meta.error && (
                                    <div
                                      className="error"
                                      style={{
                                        color: "red",
                                        fontSize: "0.7rem",
                                      }}
                                    >
                                      {meta.error}
                                    </div>
                                  )}
                                </>
                              )}
                            </Field>
                          </Form.Group>
                        </div>

                        {/* University */}

                        <div className="col-md-5">
                          <Form.Group className="mb-4" controlId="uni">
                            <Form.Label>Dental University</Form.Label>
                            <Field name="dentalUniversityId">
                              {({ field, form, meta }) => (
                                <>
                                  <Form.Select
                                    aria-label="Default select example"
                                    className=""
                                    {...field}
                                  >
                                    <option defaultValue={0}>
                                      Select University
                                    </option>

                                    {universitiesFromApi.map((item, index) => (
                                      <optgroup key={index} label={item.state}>
                                        <option key={item.id} value={item.id}>
                                          {item.name}
                                        </option>
                                      </optgroup>
                                    ))}
                                  </Form.Select>
                                  {meta.touched && meta.error && (
                                    <div
                                      className="error"
                                      style={{
                                        color: "red",
                                        fontSize: "0.7rem",
                                      }}
                                    >
                                      {meta.error}
                                    </div>
                                  )}
                                </>
                              )}
                            </Field>
                          </Form.Group>
                        </div>

                        <div className="col-md-4">
                          <Form.Group className="mb-3" controlId="graduation">
                            <Form.Label>Graduaton Date</Form.Label>
                            <Field name="graduationDate">
                              {({ field, form, meta }) => (
                                <>
                                  <Form.Control
                                    // disabled
                                    type="date"
                                    {...field}
                                    className="form-control form-control-lg border p-2"
                                  />
                                  {meta.touched && meta.error && (
                                    <div
                                      className="error"
                                      style={{
                                        color: "red",
                                        fontSize: "0.7rem",
                                      }}
                                    >
                                      {meta.error}
                                    </div>
                                  )}
                                </>
                              )}
                            </Field>
                          </Form.Group>
                        </div>

                        {/* Age */}
                        <div className="col-md-3">
                          <Form.Group className="mb-3" controlId="age">
                            <Form.Label>Age</Form.Label>
                            <Field name="age">
                              {({ field, form, meta }) => (
                                <>
                                  <Form.Control
                                    disabled
                                    type="text"
                                    {...field}
                                    className="p-2"
                                  />
                                  {meta.touched && meta.error && (
                                    <div
                                      className="error"
                                      style={{
                                        color: "red",
                                        fontSize: "0.7rem",
                                      }}
                                    >
                                      {meta.error}
                                    </div>
                                  )}
                                </>
                              )}
                            </Field>
                          </Form.Group>
                        </div>
                        {/* Age */}
                        <div className="col-md-4">
                          <Form.Group className="mb-3" controlId="gender">
                            <Form.Label>Gender</Form.Label>
                            <Field name="gender">
                              {({ field, form, meta }) => (
                                <>
                                  <Form.Control
                                    disabled
                                    type="text"
                                    {...field}
                                    className="p-2"
                                  />
                                  {meta.touched && meta.error && (
                                    <div
                                      className="error"
                                      style={{
                                        color: "red",
                                        fontSize: "0.7rem",
                                      }}
                                    >
                                      {meta.error}
                                    </div>
                                  )}
                                </>
                              )}
                            </Field>
                          </Form.Group>
                        </div>

                        {/* Issue State */}
                        <div className="col-md-4">
                          <Form.Group className="mb-3" controlId="issueState">
                            <Form.Label>Issue State</Form.Label>
                            <Field name="issueState">
                              {({ field, form, meta }) => (
                                <>
                                  <Form.Control
                                    disabled
                                    type="text"
                                    {...field}
                                    className="p-2"
                                  />
                                  {meta.touched && meta.error && (
                                    <div
                                      className="error"
                                      style={{
                                        color: "red",
                                        fontSize: "0.7rem",
                                      }}
                                    >
                                      {meta.error}
                                    </div>
                                  )}
                                </>
                              )}
                            </Field>
                          </Form.Group>
                        </div>

                        {/* Expiry Date */}
                        <div className="col-md-4">
                          <Form.Group className="mb-3" controlId="expiry">
                            <Form.Label>Expiry Date</Form.Label>
                            <Field name="expiryDate">
                              {({ field, form, meta }) => (
                                <>
                                  <Form.Control
                                    disabled
                                    type="date"
                                    {...field}
                                    className="form-control p-2"
                                  />
                                  {meta.touched && meta.error && (
                                    <div
                                      className="error"
                                      style={{
                                        color: "red",
                                        fontSize: "0.7rem",
                                      }}
                                    >
                                      {meta.error}
                                    </div>
                                  )}
                                </>
                              )}
                            </Field>
                          </Form.Group>
                        </div>

                        {/* Document Number */}
                        <div className="col-md-4">
                          <Form.Group className="mb-3" controlId="docNum">
                            <Form.Label>Document Number</Form.Label>
                            <Field name="documentNumber">
                              {({ field, form, meta }) => (
                                <>
                                  <Form.Control
                                    disabled
                                    type="text"
                                    {...field}
                                    className="p-2"
                                  />
                                  {meta.touched && meta.error && (
                                    <div
                                      className="error"
                                      style={{
                                        color: "red",
                                        fontSize: "0.7rem",
                                      }}
                                    >
                                      {meta.error}
                                    </div>
                                  )}
                                </>
                              )}
                            </Field>
                          </Form.Group>
                        </div>

                        {/* Date of Birth */}
                        <div className="col-md-4">
                          <Form.Group className="mb-3" controlId="dob">
                            <Form.Label>Date of Birth</Form.Label>
                            <Field name="dob">
                              {({ field, form, meta }) => (
                                <>
                                  <Form.Control
                                    disabled
                                    type="date"
                                    {...field}
                                    className="form-control p-2"
                                  />
                                  {meta.touched && meta.error && (
                                    <div
                                      className="error"
                                      style={{
                                        color: "red",
                                        fontSize: "0.7rem",
                                      }}
                                    >
                                      {meta.error}
                                    </div>
                                  )}
                                </>
                              )}
                            </Field>
                          </Form.Group>
                        </div>

                        {/* Phone */}
                        <div className="col-md-4">
                          <Form.Group className="mb-4" controlId="mobile">
                            <Form.Label>Mobile</Form.Label>
                            <Field name="mobile">
                              {({ field, form, meta }) => (
                                <>
                                  {/* <Form.Control
                                  type="text"
                                  {...field}
                                  className="p-2 border"
                                /> */}
                                  <PhoneNumber
                                    field={field}
                                    setFieldValue={setFieldValue}
                                  />
                                  {meta.touched && meta.error && (
                                    <div
                                      className="error"
                                      style={{
                                        color: "red",
                                        fontSize: "0.7rem",
                                      }}
                                    >
                                      {meta.error}
                                    </div>
                                  )}
                                </>
                              )}
                            </Field>
                          </Form.Group>
                        </div>

                        {/*password */}
                        <div className="col-md-4">
                          <Form.Group className="mb-3" controlId="password">
                            <Form.Label>Password</Form.Label>
                            <Field name="password">
                              {({ field, form, meta }) => (
                                <>
                                  <Form.Control
                                    onChange={(e) =>
                                      setPasswordState(e.target.value)
                                    }
                                    // maxLength="8"
                                    value={passwordState}
                                    type={passwordType}
                                    // {...field}
                                    className="border form-control py-1 px-2 form-control"
                                    style={{
                                      fontSize: "1rem",
                                    }}
                                  />

                                  {/* ========================================================= */}
                                  {passwordState.length === 0 ? (
                                    <div
                                      className="error"
                                      style={{
                                        color: "red",
                                        fontSize: "0.7rem",
                                      }}
                                    >
                                      Please Enter your password.
                                    </div>
                                  ) : /^(?=.*[a-z])(?=.*[A-Z])(?=.*[0-9])(?=.*[!@#\$%\^&\*])(?=.{8,})/.test(
                                      passwordState
                                    ) ? null : (
                                    <div
                                      className="error"
                                      style={{
                                        color: "red",
                                        fontSize: "0.7rem",
                                      }}
                                    >
                                      Must Contain One Uppercase, One Lowercase,
                                      One Number and One Special Case Character.
                                    </div>
                                  )}
                                  {/* ============================================================= */}
                                  {/* {meta.touched && meta.error && (
                                    <div
                                      className="error"
                                      style={{
                                        color: "red",
                                        fontSize: "0.7rem",
                                      }}
                                    >
                                      {meta.error}
                                    </div>
                                  )} */}
                                </>
                              )}
                            </Field>
                          </Form.Group>
                        </div>
                        {/*confirm password */}
                        <div className="col-md-4 ">
                          <Form.Group
                            className="mb-3"
                            controlId="confirmpassword"
                          >
                            <Form.Label>Confirm Password</Form.Label>
                            <Field name="confirmpassword">
                              {({ field, form, meta }) => (
                                <>
                                  <Form.Control
                                    type={passwordType}
                                    onChange={(e) =>
                                      setConfirmPasswordState(e.target.value)
                                    }
                                    // maxLength="8"
                                    // {...field}
                                    className="border form-control py-1 px-2"
                                    style={{ fontSize: "1rem" }}
                                  />

                                  {/* =================================================== */}
                                  {passwordState ===
                                  confirmPasswordState ? null : (
                                    <div
                                      className="error"
                                      style={{
                                        color: "red",
                                        fontSize: "0.7rem",
                                      }}
                                    >
                                      Passwords must be same.
                                    </div>
                                  )}

                                  {/* =================================================== */}
                                  {/* {meta.touched && meta.error && (
                                    <div
                                      className="error"
                                      style={{
                                        color: "red",
                                        fontSize: "0.7rem",
                                      }}
                                    >
                                      {meta.error}
                                    </div>
                                  )} */}
                                </>
                              )}
                            </Field>
                          </Form.Group>
                        </div>

                        <div class="col-md-6 form-check mx-4 mb-4">
                          <input
                            class="form-check-input"
                            type="checkbox"
                            value=""
                            onChange={togglePassword}
                            id="flexCheckDefault"
                          />
                          <label
                            class="form-check-label"
                            for="flexCheckDefault"
                          >
                            Show Password
                          </label>
                        </div>
                        {/* Address */}
                        <div className="col-md-10">
                          <Form.Group className="mb-3" controlId="address">
                            <Form.Label>Address</Form.Label>
                            <Field name="address">
                              {({ field, form, meta }) => (
                                <>
                                  <Form.Control
                                    // disabled
                                    type="text"
                                    {...field}
                                    className="border form-control p-2"
                                  />
                                  {meta.touched && meta.error && (
                                    <div
                                      className="error"
                                      style={{
                                        color: "red",
                                        fontSize: "0.7rem",
                                      }}
                                    >
                                      {meta.error}
                                    </div>
                                  )}
                                </>
                              )}
                            </Field>
                          </Form.Group>
                        </div>
                      </div>
                    </div>
                    {/* </div> */}
                    <div className="d-flex justify-content-center">
                      <button type="submit" className="btn btn-primary">
                        Submit
                      </button>
                    </div>
                    {/* </div> */}
                    {/* </div> */}
                    {/* )} */}
                  </FormikForm>
                )}
              </Formik>
            )}
          </div>
        </section>
      </div>

      <Footer />
    </>
  );
};

export default UserDetails;
