import CryptoJS from "crypto-js";

export const cryptoEncrypt = (data) => {
  let encryptedData = CryptoJS.AES.encrypt(
    JSON.stringify(data),
    `${process.env.REACT_APP_CRYPTO_KEY}`
  ).toString();

  return encryptedData;
};

// export const cryptoDecrypt = (data) => {
//   let bytes = CryptoJS.AES.decrypt(data, "key123");
//   let decryptedData = JSON.parse(bytes.toString(CryptoJS.enc.Utf8));
//   return decryptedData;
// };
