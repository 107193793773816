import ls from "localstorage-slim";
export const removeVar = () => {
  ls.remove("e31@0");
  ls.remove("t79i1d");
  ls.remove("@66$$7");
  ls.remove("@M0n1");
  ls.remove("11D");
  ls.remove("5ni11d");
  ls.remove("u0nqwd");
  ls.remove("lgh#pw1");
  ls.remove("f832$n");
  ls.remove("amyte213");
  ls.remove("di0c3t");
  ls.remove("0a1r0t");
  ls.remove("c0r3w");
  ls.remove("0dgfd@");
};
