import React, { Component } from "react";
import { Link } from "react-router-dom";

// Images
import Logo from "../../images/pw-dds-logo.png";
import footerBg from "../../images/background/footer.jpg";
// import ptImg1 from "../../images/shap/wave-blue.png";
import ptImg2 from "../../images/shap/circle-dots.png";
// import ptImg3 from "../../images/shap/plus-blue.png";
// import ptImg4 from "../../images/shap/wave-blue.png";

// Social Images
import facebook from "../../images/social/facebook.png";
import twitter from "../../images/social/twitter.png";
import instagram from "../../images/social/instagram.png";
import linkedin from "../../images/social/linkedin.png";

import TeethRight from "../../images/shap/teeth-right.png";
import TeethLeft from "../../images/shap/teeth-left.png";

class aboutSection extends Component {
  render() {
    return (
      <>
        <footer
          className="footer"
          style={{ backgroundImage: "url(" + footerBg + ")" }}
        >
          <div className="footer-top">
            <div className="container">
              <div className="row">
                <div className="col-xl-4 col-lg-4 col-md-6">
                  <div className="widget widget_info">
                    <div className="footer-logo">
                      <Link to="/">
                        <img src={Logo} alt="" />
                      </Link>
                    </div>
                    <div className="ft-contact">
                      <p>
                        PracticeWise is the first of its kind: a dedicated cross
                        disciplinary platform of business experts that provides
                        best practices and critical support elements for dental
                        practices and the dentists who run them, all in one
                        simple, easy-to-join network.
                      </p>
                      <div className="contact-bx">
                        <div className="icon">
                          <i className="fas fa-phone-alt"></i>
                        </div>
                        <div className="contact-number">
                          <span>Contact Us</span>
                          <h4 className="number">+1-917-301-2522</h4>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
                <div className="col-xl-4 col-lg-4 col-md-6">
                  <div className="widget footer_widget ml-50">
                    <h3 className="footer-title">Quick Links</h3>
                    <ul>
                      <li className="">
                        <a href="/#help">
                          <span>How We Help</span>
                        </a>
                      </li>
                      <li className="">
                      <Link to="/about-us">About Us</Link>
                    </li>
                      <li className="">
                        <Link to="/solutions">
                          <span>Solutions</span>
                        </Link>
                      </li>
                      <li className="">
                        <Link to="/contact-us">
                          <span>Contact Us</span>
                        </Link>
                      </li>
                      <li className="">
                        <Link to="/terms">
                          <span>Terms &amp; Conditions</span>
                        </Link>
                      </li>
                      <li className="">
                        <Link to="/privacy-policy">
                          <span>Privacy Policy</span>
                        </Link>
                      </li>
                      {/* <li>
                        <Link to="/faq">
                          <span>Faq's</span>
                        </Link>
                      </li> */}
                      {/* <li>
                        <Link to="/blog-grid">
                          <span>Blogs</span>
                        </Link>
                      </li>
                      <li>
                        <Link to="/team">
                          <span>Out Team</span>
                        </Link>
                      </li> */}
                    </ul>
                  </div>
                </div>
                {/* <div className="col-xl-3 col-lg-3 col-6">
                  <div className="widget footer_widget">
                    <h3 className="footer-title">Our Service</h3>
                    <ul>
                      <li>
                        <Link to="/service-detail">
                          <span>Dental Care</span>
                        </Link>
                      </li>
                      <li>
                        <Link to="/service-detail">
                          <span>Cardiac Clinic</span>
                        </Link>
                      </li>
                      <li>
                        <Link to="/service-detail">
                          <span>Massege Therapy</span>
                        </Link>
                      </li>
                      <li>
                        <Link to="/service-detail">
                          <span>Cardiology</span>
                        </Link>
                      </li>
                      <li>
                        <Link to="/service-detail">
                          <span>Precise Diagnosis</span>
                        </Link>
                      </li>
                      <li>
                        <Link to="/service-detail">
                          <span>Abmbulance Services</span>
                        </Link>
                      </li>
                    </ul>
                  </div>
                </div> */}
                <div className="col-xl-4 col-lg-4 col-md-6">
                  <div className="widget widget_form">
                    <h3 className="footer-title">Subscribe</h3>
                    <form className="subscribe-form subscription-form mb-30">
                      <div className="ajax-message"></div>
                      <div className="input-group">
                        <input
                          name="email"
                          required="required"
                          className="form-control"
                          placeholder="Email Address"
                          type="email"
                        />
                      </div>
                      <button
                        name="submit"
                        value="Submit"
                        type="submit"
                        className="btn btn-secondary shadow w-100"
                      >
                        Subscribe Now
                      </button>
                    </form>
                    <div className="footer-social-link">
                      <ul>
                        <li>
                          <a
                            target="_blank"
                            rel="noreferrer"
                            href="https://fb.me/practicewisedds"
                          >
                            <img src={facebook} alt="" />
                          </a>
                        </li>
                        <li>
                          <a
                            target="_blank"
                            rel="noreferrer"
                            href="https://twitter.com/PWisedds"
                          >
                            <img src={twitter} alt="" />
                          </a>
                        </li>
                        <li>
                          <a
                            target="_blank"
                            rel="noreferrer"
                            href="https://www.instagram.com/pwisedds/"
                          >
                            <img src={instagram} alt="" />
                          </a>
                        </li>
                        <li>
                          <a
                            target="_blank"
                            rel="noreferrer"
                            href="https://www.linkedin.com/company/practicewisedds/?viewAsMember=true"
                          >
                            <img src={linkedin} alt="" />
                          </a>
                        </li>
                      </ul>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
          <div className="container">
            <div className="footer-bottom">
              <div className="row">
                <div className="col-12 text-center">
                  <p className="copyright-text">
                    Copyright © 2022 Practice Wise DDS. All Rights Reserved.
                    {/* Design & Developed by{" "} */}
                    {/* <a
                      href="https://themeforest.net/user/themetrades"
                      rel="noreferrer"
                      target="_blank"
                      className="text-secondary"
                    >
                      ThemeTrades
                    </a> */}
                  </p>
                </div>
              </div>
            </div>
          </div>
          <img className="pt-img1 animate-wave" src={TeethLeft} alt="" />
          <img className="pt-img2 animate1" src={ptImg2} alt="" />
          {/* <img className="pt-img3 animate-rotate" src={ptImg3} alt="" /> */}
          <img className="pt-img4 animate-wave" src={TeethRight} alt="" />
        </footer>
      </>
    );
  }
}

export default aboutSection;
