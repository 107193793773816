import React, { Component } from "react";

class LatestNewsSection extends Component {
  render() {
    return (
      <>
        <section className="section-sp1 service-wraper2">
          <div className="container">
            <div className="row">
              <div className="col-xl-3 col-sm-6 mb-30">
                <div className="feature-container feature-bx3">
                  <h2 className="counter text-secondary">80</h2>
                  <h5 className="ttr-title">Years With You</h5>
                  <p>
                    80-years of time-tested experience to help you avoid costly
                    mistakes.
                  </p>
                </div>
              </div>
              <div className="col-xl-3 col-sm-6 mb-30">
                <div className="feature-container feature-bx3">
                  <h2 className="counter text-secondary">100</h2>
                  <h5 className="ttr-title">Accolades</h5>
                  <p>
                    Our Directors are passionate about implementing a positive
                    influence.
                  </p>
                </div>
              </div>
              <div className="col-xl-3 col-sm-6 mb-30">
                <div
                  className="feature-container feature-bx3"
                  style={{
                    padding: "4.5em 2em",
                  }}
                >
                  <h2 className="counter text-secondary fs-2">1,000,000s</h2>
                  <h5 className="ttr-title">$0 Overpaid</h5>
                  <p>
                    Millions in interest overpayments avoided on student loan
                    debt.
                  </p>
                </div>
              </div>
              <div className="col-xl-3 col-sm-6 mb-30">
                <div className="feature-container feature-bx3">
                  <h2 className="counter text-secondary fs-2">800</h2>
                  <h5 className="ttr-title">Raise Your Score</h5>
                  <p>
                    Helping every member achieve their best FICO credit score.
                  </p>
                </div>
              </div>
            </div>
          </div>
        </section>
      </>
    );
  }
}

export default LatestNewsSection;
