import React, { useState, useEffect } from "react";
import { ProgressBar } from "react-bootstrap";
import ls from "localstorage-slim";
// Layout
import Footer from "../layout/footer";
import axios from "axios";

import "../../css/join-now.css";
import JoinSection from "../elements/join-section";
import GoalQuestion from "../elements/goal-question";
import EmployeeStatus from "../elements/employee-status";
import DentalQuestion from "../elements/dental-question";
import ClubQuestion from "./club-question";
import Congrats from "../elements/congrats";
import { useHistory } from "react-router-dom";
import { cryptoEncrypt } from "../helperFunctions/DataTransit";
// import Test from "../elements/Test";

const Questionnaire = () => {
  const [btnNextAndBack, setBtnNextAndBack] = useState(0);
  const [fillProgress, setFillProgress] = useState(0);
  const [goal, setGoal] = useState(0);
  const [dental, setDental] = useState([]);
  const [club, setClub] = useState([]);
  const [employeeStatus, setEmployeeStatus] = useState("");

  const history = useHistory();
  if (ls.get("0a1r0t", { decrypt: true }) !== true) history.push("/");

  const fillProgressHandler = () => {
    switch (btnNextAndBack) {
      case 0:
        setFillProgress(fillProgress + 20);
        break;
      case 1:
        setFillProgress(fillProgress + 20);
        break;
      case 2:
        setFillProgress(fillProgress + 40);
        break;
      case 3:
        setFillProgress(fillProgress + 20);
        break;
      default:
        return fillProgress;
    }
  };

  const removeProgressHandler = () => {
    switch (btnNextAndBack) {
      case 0:
        setFillProgress(2);
        break;
      case 1:
        setFillProgress(fillProgress - 20);
        break;
      case 2:
        setFillProgress(fillProgress - 20);
        break;
      case 3:
        setFillProgress(fillProgress - 40);
        break;
      case 4:
        setFillProgress(fillProgress - 20);
        break;
      default:
        return fillProgress;
    }
  };

  const updateUserDetail = async () => {
    let encData = cryptoEncrypt({
      employmentStatus: employeeStatus,
      goal,
      dental_interest: dental,
      club_associations: club,
    });
    try {
      await axios.put(
        `${process.env.REACT_APP_BACKEND}/user/${ls.get("11D", {
          decrypt: true,
        })}`,
        { data: encData }
      );
    } catch (error) {
      return error;
    }
  };

  useEffect(() => {
    if (btnNextAndBack === 4) {
      updateUserDetail();
    }
  }, [btnNextAndBack]);

  return (
    <>
      <div className="page-content bg-white">
        <JoinSection />
        <div className="container w-50 text-center my-2">
          <h3 className="mb-2 text-secondary" style={{ fontSize: "1rem" }}>
            You Have Completed{" "}
            <span className="fw-bold">
              {fillProgress === 2 ? 0 : fillProgress}%
            </span>{" "}
            Progress
          </h3>
          <ProgressBar now={fillProgress ? fillProgress : 2} />
        </div>
        <section className="">
          <div className="container bg-white shadow p-4 border rounded custom-bg">
            {btnNextAndBack === 0 && (
              <GoalQuestion goal={goal} setGoal={setGoal} />
            )}
            {btnNextAndBack === 1 && (
              <DentalQuestion setDental={setDental} dental={dental} />
            )}
            {btnNextAndBack === 2 && (
              <ClubQuestion setClub={setClub} club={club} />
            )}
            {btnNextAndBack === 3 && (
              <EmployeeStatus
                employeeStatus={employeeStatus}
                setEmployeeStatus={setEmployeeStatus}
              />
            )}
            {btnNextAndBack === 4 && <Congrats />}
            {/* <Test /> */}
          </div>
        </section>

        <div className="container w-75 mt-2">
          <div
            className={`d-flex ${
              btnNextAndBack === 0
                ? "justify-content-end"
                : btnNextAndBack === 4
                ? "justify-content-center"
                : "justify-content-between"
            }`}
          >
            {btnNextAndBack === 0 ? null : btnNextAndBack === 4 ? null : (
              <button
                type="button"
                className="btn btn-secondary shadow mx-2"
                onClick={() => {
                  setBtnNextAndBack(btnNextAndBack - 1);
                  removeProgressHandler();
                }}
              >
                Back
              </button>
            )}
            {btnNextAndBack >= 3 ? (
              <button
                type="button"
                className="btn btn-primary shadow mx-2"
                onClick={() => {
                  if (btnNextAndBack === 4) {
                    history.push("/on-boarding/pricing");
                  } else {
                    setBtnNextAndBack(btnNextAndBack + 1);
                    fillProgressHandler();
                  }
                }}
              >
                Continue
              </button>
            ) : (
              <button
                type="button"
                className="btn btn-primary shadow mx-2"
                onClick={() => {
                  setBtnNextAndBack(btnNextAndBack + 1);
                  fillProgressHandler();
                }}
              >
                Next
              </button>
            )}
          </div>
        </div>
      </div>

      <Footer />
    </>
  );
};

export default Questionnaire;
