import axios from "axios";
import { cryptoEncrypt } from "../helperFunctions/DataTransit";

export const emailLookup = async (email) => {
  let encData = cryptoEncrypt({ email });
  return await axios.post(`${process.env.REACT_APP_BACKEND}/user/lookup`, {
    data: encData,
  });
};

export const getUniversities = async () => {
  return await axios.get(`${process.env.REACT_APP_BACKEND}/university`);
};

export const checkUniversity = async (id) => {
  try {
    let res = await axios.get(
      `${process.env.REACT_APP_BACKEND}/user/lookup/university/${id}`
    );
    return res;
  } catch (error) {
    return error;
  }
};
