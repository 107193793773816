import React from "react";
import { Link } from "react-router-dom";
import { Formik, Form as FormikForm, Field } from "formik";
import * as Yup from "yup";
import axios from "axios";
// Layout
import Header from "../layout/header2";
import Footer from "../layout/footer";
import { toast } from "react-toastify";
// Import Images
import bnrImg1 from "../../images/banner/img1.jpg";
import pic1 from "../../images/about/pic-1.jpg";
import icon1 from "../../images/icon/icon1.png";
import icon2 from "../../images/icon/icon2.png";
import icon3 from "../../images/icon/icon3.png";
import animate2 from "../../images/shap/circle-dots.png";
import TeethRight from "../../images/shap/teeth-right.png";
import TeethLeft from "../../images/shap/teeth-left.png";
import { cryptoEncrypt } from "../helperFunctions/DataTransit";

const contactForm = Yup.object().shape({
  email: Yup.string()
    .email("Invalid email format")
    .required("Field is Required"),
  name: Yup.string().required("Field is Required"),
  message: Yup.string().required("Field is Required"),
  phone: Yup.string().required("Field is Required"),
  department: Yup.string().required("Field is Required"),
});
function ContactUs() {
  const sendQuery = async (data) => {
    let encData = cryptoEncrypt(data);
    // let config = {
    //   headers: {
    //     "Content-Type": "application/x-www-form-urlencoded",
    //     Accept: "application/json",
    //     Host: "practicewise-server.herokuapp.com",
    //   },
    // };

    // let encConfig = cryptoEncrypt(config);

    try {
      const res = await axios.post(
        `${process.env.REACT_APP_BACKEND}/contact-us/send/query`,

        { data: encData }
      );
      toast.info(res.data.message);
      // return res;
    } catch (error) {
      return error;
    }
  };
  const initialValues = {
    email: "",
    name: "",
    message: "",
    phone: "",
    department: "",
  };
  return (
    <>
      <Header />

      <div className="page-content bg-white">
        <div className="banner-wraper">
          <div
            className="page-banner banner-lg contact-banner"
            style={{ backgroundImage: "url(" + bnrImg1 + ")" }}
          >
            <div className="container">
              <div className="page-banner-entry text-center">
                <h1>Contact Us</h1>
                <nav aria-label="breadcrumb" className="breadcrumb-row">
                  <ul className="breadcrumb">
                    <li className="breadcrumb-item">
                      <Link to="/">
                        <svg
                          xmlns="http://www.w3.org/2000/svg"
                          width="22"
                          height="22"
                          viewBox="0 0 24 24"
                          fill="none"
                          stroke="currentColor"
                          strokeWidth="2"
                          strokeLinecap="round"
                          strokeLinejoin="round"
                          className="feather feather-home"
                        >
                          <path d="M3 9l9-7 9 7v11a2 2 0 0 1-2 2H5a2 2 0 0 1-2-2z"></path>
                          <polyline points="9 22 9 12 15 12 15 22"></polyline>
                        </svg>{" "}
                        Home
                      </Link>
                    </li>
                    <li className="breadcrumb-item active" aria-current="page">
                      Contact Us
                    </li>
                  </ul>
                </nav>
              </div>
            </div>
            <img className="pt-img1 animate-wave" src={TeethLeft} alt="" />
            <img className="pt-img2 animate2" src={animate2} alt="" />
            <img className="pt-img3 animate-wave" src={TeethRight} alt="" />
          </div>
        </div>

        <section className="">
          <div className="container">
            <div className="contact-wraper">
              <div className="row">
                <div className="col-lg-12 mb-15">
                  <h3>We're here to help you level up</h3>
                  <p>
                    We're just one click away to help you take your brand or
                    product from great to incredible. Fill in the form to share
                    more details about your project. Or your favorite gum
                    flavor. Either way, we'd love to talk.
                  </p>
                </div>
                <div className="col-lg-6 mb-30">
                  <Formik
                    enableReinitialize
                    initialValues={initialValues}
                    validationSchema={contactForm}
                    className="form-wraper contact-form ajax-form"
                    onSubmit={async (values, { resetForm }) => {
                      // console.log("values", values);
                      await sendQuery(values);
                      resetForm();
                    }}
                  >
                    {({ setFieldValue, errors, touched }) => (
                      <FormikForm>
                        <div className="ajax-message"></div>
                        <div className="row">
                          <div className="form-group col-md-12 my-3">
                            <Field
                              name="name"
                              type="text"
                              required
                              className="form-control border"
                              placeholder="Your Name"
                            />
                            {errors.name && touched.name ? (
                              <div style={{ fontSize: "0.7rem", color: "red" }}>
                                {errors.name}
                              </div>
                            ) : null}
                          </div>
                          <div className="form-group col-md-12 my-3">
                            <Field
                              name="email"
                              type="email"
                              required
                              className="form-control border"
                              placeholder="Email"
                            />
                            {errors.email && touched.email ? (
                              <div style={{ fontSize: "0.7rem", color: "red" }}>
                                {errors.email}
                              </div>
                            ) : null}
                          </div>

                          <div className="form-group col-md-12 my-3">
                            <Field
                              name="phone"
                              type="text"
                              required
                              className="form-control border"
                              placeholder="Phone Numbers"
                            />
                            {errors.phone && touched.phone ? (
                              <div style={{ fontSize: "0.7rem", color: "red" }}>
                                {errors.phone}
                              </div>
                            ) : null}
                          </div>
                          <div className="form-group col-md-12 my-3">
                            <Field name="department">
                              {({ field, form, meta }) => (
                                <>
                                  <select
                                    {...field}
                                    className="form-select form-control border"
                                  >
                                    <option>Select Department</option>
                                    <option value="Onboarding">
                                      Onboarding
                                    </option>
                                    <option value="Payment">Payment</option>
                                    <option value="Other">Other</option>
                                  </select>
                                  {meta.touched && meta.error && (
                                    <div
                                      className="error"
                                      style={{
                                        color: "red",
                                        fontSize: "0.7rem",
                                      }}
                                    >
                                      {meta.error}
                                    </div>
                                  )}
                                </>
                              )}
                            </Field>
                          </div>
                          <div className="form-group col-md-12 my-3">
                            <Field name="message">
                              {({ field, form, meta }) => (
                                <>
                                  <textarea
                                    {...field}
                                    name="message"
                                    required
                                    className="form-control border"
                                    placeholder="Type Message"
                                  ></textarea>
                                  {meta.touched && meta.error && (
                                    <div
                                      className="error"
                                      style={{
                                        color: "red",
                                        fontSize: "0.7rem",
                                      }}
                                    >
                                      {meta.error}
                                    </div>
                                  )}
                                </>
                              )}
                            </Field>
                          </div>
                          <div className="col-lg-12">
                            <button
                              name="submit"
                              type="submit"
                              // value="Submit"
                              className="btn w-100 btn-secondary btn-lg"
                            >
                              Submit
                            </button>
                          </div>
                        </div>
                      </FormikForm>
                    )}
                  </Formik>
                </div>
                <div className="col-lg-6 mb-30">
                  <div
                    className="contact-info ovpr-dark"
                    style={{ backgroundImage: "url(" + pic1 + ")" }}
                  >
                    <div className="info-inner">
                      <h4 className="title mb-30">
                        Contact Us For Any Informations
                      </h4>
                      <div className="icon-box">
                        <h6 className="title">
                          <i className="ti-map-alt"></i>Location
                        </h6>
                        <p>Address: P.O. Box 20216, Brooklyn, NY 11202</p>
                      </div>
                      <div className="icon-box">
                        <h6 className="title">
                          <i className="ti-id-badge"></i>Email &amp; Phone
                        </h6>
                        <Link to="#" className="text-white">
                          support@practicewisedds.com
                        </Link>
                        <p>+1-917-301-2522 </p>
                      </div>
                      <div className="icon-box">
                        <h6 className="title">
                          <i className="ti-world"></i>Follow Us
                        </h6>
                        <ul className="social-media">
                          <li>
                            <a
                              rel="noreferrer"
                              target="_blank"
                              href="https://twitter.com/PWisedds"
                            >
                              <i className="fab fa-twitter"></i>
                            </a>
                          </li>
                          <li>
                            <a
                              rel="noreferrer"
                              target="_blank"
                              href="https://www.linkedin.com/company/practicewisedds/?viewAsMember=true"
                            >
                              <i className="fab fa-linkedin"></i>
                            </a>
                          </li>
                          <li>
                            <a
                              rel="noreferrer"
                              target="_blank"
                              href="https://www.instagram.com/pwisedds/"
                            >
                              <i className="fab fa-instagram"></i>
                            </a>
                          </li>
                        </ul>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </section>

        <section className="section-area section-sp1">
          <div className="container">
            <div className="row">
              <div className="col-lg-4 col-md-6 mb-30">
                <div className="feature-container feature-bx4 feature4">
                  <div className="icon-md feature-icon">
                    <img src={icon1} alt="" />
                  </div>
                  <div className="icon-content">
                    <h5 className="ttr-title">Contact Number</h5>
                    <p>+1-917-301-2522</p>
                    {/* <p>+002 3424 44 00</p> */}
                  </div>
                </div>
              </div>
              <div className="col-lg-4 col-md-6 mb-30">
                <div className="feature-container feature-bx4 feature3">
                  <div className="icon-md feature-icon">
                    <img src={icon3} alt="" />
                  </div>
                  <div className="icon-content">
                    <h5 className="ttr-title">Email Address</h5>
                    <p>support@practicewisedds.com</p>
                    {/* <p>example@support.com</p> */}
                  </div>
                </div>
              </div>
              <div className="col-lg-4 col-md-6 mb-30">
                <div className="feature-container feature-bx4 feature2">
                  <div className="icon-md feature-icon">
                    <img src={icon2} alt="" />
                  </div>
                  <div className="icon-content">
                    <h5 className="ttr-title">Address</h5>
                    <p>Address: P.O. Box 20216, Brooklyn, NY 11202</p>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </section>
      </div>

      <Footer />
    </>
  );
}

export default ContactUs;
