import React, { Component } from "react";
import { BrowserRouter, Route, Switch } from "react-router-dom";

// Elements
import BackToTop from "./elements/back-top";
import PageScrollTop from "./elements/page-scroll-top";

// All Pages Router
import Index from "./pages/index";
import AboutUs from "./pages/about-us";
// import Team from "./pages/team";
// import Services from "./pages/services";
// import ServiceDetail from "./pages/service-detail";
// import FormLogin from "./pages/form-login";
// import FormRegister from "./pages/form-register";
// import FormForgetPassword from "./pages/form-forget-password";
// import Faq from "./pages/faq";
import JoinNow from "./pages/join-now";
import OnBoarding from "./pages/on-boarding";
import ContactUs from "./pages/contact-us";
// import Booking from "./pages/booking";
// import BlogGrid from "./pages/blog-grid";
// import BlogDetails from "./pages/blog-details";
import Error from "./pages/error-404";
import Solution from "./pages/solution";
import UserDetails from "./pages/user-details";
import Questionnaire from "./pages/questionnaire";
import Payment from "./pages/payment";
import TwoFactorAuth from "./pages/two-factor-auth";
import Pricing from "./pages/pricing";
import Terms from "./pages/terms";
import PrivacyPolicy from "./pages/privacy-policy";
import HowWeHelpPage from "./pages/how-we-help-page";

class Markup extends Component {
  render() {
    return (
      <>
        <BrowserRouter
        // basename={"/react/"}
        >
          <Switch>
            <Route path="/" exact component={Index} />
            <Route path="/about-us" exact component={AboutUs} />
            {/* <Route path="/team" exact component={Team} /> */}
            {/* <Route path="/services" exact component={Services} /> */}
            {/* <Route path="/service-detail" exact component={ServiceDetail} /> */}
            <Route path="/solutions" exact component={Solution} />
            <Route path="/how-we-help" exact component={HowWeHelpPage} />
            {/* <Route path="/form-login" exact component={FormLogin} /> */}
            {/* <Route path="/form-register" exact component={FormRegister} /> */}
            {/* <Route
              path="/form-forget-password"
              exact
              component={FormForgetPassword}
            /> */}
            {/* <Route path="/faq" exact component={Faq} /> */}
            <Route path="/terms" exact component={Terms} />
            <Route path="/privacy-policy" exact component={PrivacyPolicy} />
            <Route path="/contact-us" exact component={ContactUs} />
            <Route path="/on-boarding/join-now" exact component={JoinNow} />
            <Route path="/on-boarding" exact component={OnBoarding} />
            <Route
              path="/on-boarding/user-details"
              exact
              component={UserDetails}
            />
            <Route
              path="/on-boarding/questionnaire"
              exact
              component={Questionnaire}
            />
            <Route path="/on-boarding/pricing" exact component={Pricing} />
            <Route path="/on-boarding/payment" exact component={Payment} />
            <Route
              path="/on-boarding/two-factor-auth"
              exact
              component={TwoFactorAuth}
            />
            {/* <Route path="/booking" exact component={Booking} /> */}
            {/* <Route path="/blog-grid" exact component={BlogGrid} /> */}
            {/* <Route path="/blog-details" exact component={BlogDetails} /> */}
            <Route component={Error} />
          </Switch>

          <PageScrollTop />
        </BrowserRouter>

        <BackToTop />
      </>
    );
  }
}

export default Markup;
