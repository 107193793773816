import React, { useState } from "react";

// Import Images
import DrGrannum from "../../images/BODs/dr.Grannum.jpeg";
import DrVincent from "../../images/BODs/dr.vincent.jpg";
import DrMichael from "../../images/BODs/dr.michael.jpg";

import ptImg1 from "../../images/shap/trangle-orange.png";
import { Modal } from "react-bootstrap";

const TeamSection = ({ setTeamMember, showTeamMember }) => {
  const [show, setShow] = useState(false);

  const handleClose = () => {
    setShow(false);
    setTeamMember(0);
  };
  const handleShow = () => {
    setShow(true);
  };
  return (
    <>
      <Modal
        size="lg"
        show={show}
        aria-labelledby="example-modal-sizes-title-lg"
        centered
        onHide={handleClose}
        backdrop={true}
        contentClassName="custom-modal-2"
      >
        {/* <Modal.Header closeButton>
          <Modal.Title>Modal title</Modal.Title>
        </Modal.Header> */}
        <Modal.Body>
          {showTeamMember === 1 && (
            <>
              <h4 className="title">Dr. Charles Grannum</h4>
              <span className="text-secondary">Prosthodontist</span>
              <div className="text-left">
                <p>
                  Dr. Charles Grannum, DMD is a Brooklyn dentist who offers
                  teeth whitening, root canal therapy, Invisalign, dentures,
                  dental implants, porcelain veneers, & more!
                </p>
                <p>
                  A proud native of Clinton Hill in Brooklyn, NY, Dr. Charles
                  Grannum received his Bachelor's Degree in Biology from the
                  University of Pennsylvania, and Doctorate of Dental Medicine
                  from Fairleigh Dickenson University School of Dentistry. Dr.
                  Grannum was certified in the specialty of Prosthodontics from
                  Columbia University School of Dental and Oral Surgery, and
                  completed residency training programs at both Woodhull and
                  Harlem Hospitals.
                </p>
                <p>
                  Dr. Grannum has been honored for excellence in his field by
                  esteemed institutions like Columbia University, Harlem
                  Hospital and Bronx Lebanon Hospital. With a passion for
                  learning and teaching, Dr. Grannum is the Director of
                  Prosthodontics and faculty member at both Interfaith and
                  Harlem Hospitals, were he teaches the specialty of
                  prosthodontics to dental residents. Dr. Grannum has sustained
                  his quality, professional care with a successful private
                  practice in Clinton Hill for over 20 years.
                </p>
                <p>
                  Dr. Grannum lives by the modest, yet profound act of giving.
                  He is a leader in his community, (having served as Board
                  Member and Secretary of the Pratt Area Community Council
                  (PACC), a member of the Protestant Board of Guardians, a
                  non-profit social services agency that keeps families together
                  through preventive services and counseling. His dedication and
                  contributions have been recognized by Emmanuel Baptist Church,
                  Bridge Street AWME Church, and numerous other organizations.
                </p>
              </div>
            </>
          )}
          {showTeamMember === 2 && (
            <>
              <h4 className="title">Mr. Michael Vincent</h4>
              <span className="text-secondary">Executive</span>
              <div className="text-left">
                <p>
                  For over 30 years, Mr. Vincent has been helping business
                  owners design and implement a full suite of cost-effective
                  insurance and benefits programs: insurance, employee benefits
                  and related Solutions for business owners and Physicians and
                  Dentists in private practice.  He’s an expert with group
                  insurance - Health, Dental, Vision, Disability, Life and
                  Long-Term Care.  He has helped 100s of business owners
                  increase employee retention through their employee benefits -
                  group benefits strategy, HR related services, employee
                  education, supplemental benefits, group medical and disability
                  insurance for medical professionals.
                </p>
                <p>
                  For over 30 years, Mr. Vincent has been helping business
                  owners design and implement a full suite of cost-effective
                  insurance and benefits programs: insurance, employee benefits
                  and related Solutions for business owners and Physicians and
                  Dentists in private practice.
                </p>
                <p>
                  He’s an expert with group insurance - Health, Dental, Vision,
                  Disability, Life and Long-Term Care. He has helped 100s of
                  business owners increase employee retention through their
                  employee benefits - group benefits strategy, HR related
                  services, employee education, supplemental benefits, group
                  medical and disability insurance for medical professionals.
                </p>
              </div>
            </>
          )}
          {showTeamMember === 3 && (
            <>
              <h4 className="title">Mr. Michael Ralph, PhD</h4>
              <span className="text-secondary">Professor</span>
              <div className="text-left">
                <p>
                  Mr. Ralph is a Professor at Howard University and Chair of the
                  Department of Afro-American Studies. He also teaches in the
                  School of Medicine at New York University. His research
                  integrates political science, economics, history, and medical
                  anthropology through an explicit focus on debt, slavery,
                  insurance, forensics, and incarceration.
                </p>
                <p>
                  His 2015 University of Chicago Press book, Forensics of
                  Capital, explores how Senegalese people determine who owes
                  what to whom, in daily interactions and in geopolitics. In
                  showing how discourses on debt are used to assess social
                  standing, he traced how Senegal became a leader of political
                  and economic reform in Africa. He treats forensics as a theory
                  of capital as well as a theory of sovereignty that explains
                  how people adjust social standing based on whether they
                  receive payment for outstanding goods and services, as well as
                  for crimes and offenses. The main argument of Forensics of
                  Capital is that the social profile of an individual or country
                  is a credit profile as well as a forensic profile.
                </p>
                <p>
                  His genius abounds has he is currently at work on two books
                  that center on slavery, insurance, and incarceration.
                </p>
                <p>
                  Life explores how the US insurance industry affixes monetary
                  value to human life with more breadth and nuance than any
                  scholar has yet attempted. Breaking with the consensus among
                  historians that slave insurance is distinct from life
                  insurance because slaves were legally classified as property,
                  Dr. Ralph demonstrates that life insurance was built from the
                  legal rationale and commercial logic of marine insurance and,
                  later, slave insurance.
                </p>
                <p>
                  If his research on insurance reveals that even people who were
                  enslaved could inhabit social standing approximating that of
                  exploited free workers, his other current book project
                  explores how citizens can be exploited as unpaid workers. Dr.
                  Ralph has a graphic non-fiction book, Before 13th –narrated by
                  Frederick Douglass—revises the scholarly consensus on the
                  history of convict leasing, demonstrating that it did not
                  begin with the 13th amendment, but several decades prior.
                </p>
              </div>
            </>
          )}
        </Modal.Body>

        <Modal.Footer>
          <div className="mt-4  text-center">
            <button className="btn btn-primary" onClick={handleClose}>
              Close
            </button>
          </div>
        </Modal.Footer>
      </Modal>
      <section className="section-area section-sp3 team-wraper">
        <div className="container">
          <div className="heading-bx text-center">
            {/* <h6 className="title-ext text-secondary">Our Doctor</h6> */}
            <h2 className="title">Meet Best Board of Directors</h2>
          </div>
          <div className="row justify-content-center">
            <div className="col-lg-3 col-sm-6 mb-30">
              {/* active */}
              <div
                className="team-member"
                style={{ cursor: "pointer" }}
                onClick={() => {
                  handleShow();
                  setTeamMember(1);
                }}
              >
                <div className="team-media">
                  <img src={DrGrannum} alt="" />
                </div>
                <div className="team-info">
                  <div className="team-info-comntent">
                    <h4 className="title">Dr. Charles Grannum</h4>
                    <span className="text-secondary">Prosthodontist</span>
                  </div>
                  {/* <ul className="social-media">
                    <li>
                      <a
                        rel="noreferrer"
                        target="_blank"
                        href="https://twitter.com/PWisedds"
                      >
                        <i className="fab fa-twitter"></i>
                      </a>
                    </li>
                    <li>
                      <a
                        rel="noreferrer"
                        target="_blank"
                        href="https://www.linkedin.com/company/practicewisedds/?viewAsMember=true"
                      >
                        <i className="fab fa-linkedin"></i>
                      </a>
                    </li>
                    <li>
                      <a
                        rel="noreferrer"
                        target="_blank"
                        href="https://www.instagram.com/pwisedds/"
                      >
                        <i className="fab fa-instagram"></i>
                      </a>
                    </li>
                  </ul> */}
                </div>
              </div>
            </div>
            <div className="col-lg-3 col-sm-6 mb-30">
              <div
                className="team-member"
                style={{ cursor: "pointer" }}
                onClick={() => {
                  handleShow();
                  setTeamMember(2);
                }}
              >
                <div className="team-media">
                  <img src={DrVincent} alt="" />
                </div>
                <div className="team-info">
                  <div className="team-info-comntent">
                    <h4 className="title">Mr. Michael Vincent</h4>
                    <span className="text-secondary">Executive</span>
                  </div>
                  {/* <ul className="social-media">
                    <li>
                      <a
                        rel="noreferrer"
                        target="_blank"
                        href="https://twitter.com/PWisedds"
                      >
                        <i className="fab fa-twitter"></i>
                      </a>
                    </li>
                    <li>
                      <a
                        rel="noreferrer"
                        target="_blank"
                        href="https://www.linkedin.com/company/practicewisedds/?viewAsMember=true"
                      >
                        <i className="fab fa-linkedin"></i>
                      </a>
                    </li>
                    <li>
                      <a
                        rel="noreferrer"
                        target="_blank"
                        href="https://www.instagram.com/pwisedds/"
                      >
                        <i className="fab fa-instagram"></i>
                      </a>
                    </li>
                  </ul> */}
                </div>
              </div>
            </div>
            <div className="col-lg-3 col-sm-6 mb-30">
              <div
                className="team-member"
                style={{ cursor: "pointer" }}
                onClick={() => {
                  handleShow();
                  setTeamMember(3);
                }}
              >
                <div className="team-media">
                  <img src={DrMichael} alt="" />
                </div>
                <div className="team-info">
                  <div className="team-info-comntent">
                    <h4 className="title">Mr. Michael Ralph, PhD</h4>
                    <span className="text-secondary">Professor</span>
                  </div>
                  {/* <ul className="social-media">
                    <li>
                      <a
                        rel="noreferrer"
                        target="_blank"
                        href="https://twitter.com/PWisedds"
                      >
                        <i className="fab fa-twitter"></i>
                      </a>
                    </li>
                    <li>
                      <a
                        rel="noreferrer"
                        target="_blank"
                        href="https://www.linkedin.com/company/practicewisedds/?viewAsMember=true"
                      >
                        <i className="fab fa-linkedin"></i>
                      </a>
                    </li>
                    <li>
                      <a
                        rel="noreferrer"
                        target="_blank"
                        href="https://www.instagram.com/pwisedds/"
                      >
                        <i className="fab fa-instagram"></i>
                      </a>
                    </li>
                  </ul> */}
                </div>
              </div>
            </div>
          </div>
        </div>
        <img className="pt-img1 animate1" src={ptImg1} alt="" />
        {/* <img className="pt-img2 animate2" src={ptImg2} alt="" /> */}
        {/* <img className="pt-img3 animate-rotate" src={ptImg3} alt="" /> */}
        {/* <img className="pt-img4 animate-wave" src={ptImg4} alt="" /> */}
        {/* <img className="pt-img5 animate-wave" src={ptImg5} alt="" /> */}
      </section>
    </>
  );
};

export default TeamSection;
