import React, { useEffect } from "react";

// Layout
import Header from "../layout/header";
import Footer from "../layout/footer";
// Elements
import MainBannerSection from "../elements/main-banner";
// import AboutSection from "../elements/about";
// import WorkSection from "../elements/work";
import WhyChooseUs from "../elements/why-choose-us";
import JoinNowtSection from "../elements/join-now-section";
// import AppointmentSection from "../elements/appointment";
// import TestimonialSection from "../elements/testimonial";
// import ServicesSliderSection from "../elements/services-slider";
// import LatestNewsSection from "../elements/latest-news-slider";
import HowWeHelp from "../elements/how-we-help";

const Index = () => {
  useEffect(() => {
    setTimeout(() => {
      console.clear();
    }, 2000);
  }, []);

  console.log(process.env.REACT_APP_ENV);

  return (
    <>
      <Header />

      <MainBannerSection />

      {/* <AboutSection /> */}
      <HowWeHelp />

      {/* <WorkSection /> */}
      <WhyChooseUs />

      {/* <AppointmentSection /> */}
      <JoinNowtSection />

      {/* <ServicesSliderSection /> */}

      {/* <TestimonialSection /> */}

      {/* <LatestNewsSection /> */}

      <Footer />
    </>
  );
};

export default Index;
