import React, { useEffect, useState } from "react";
import "../../css/form.scss";
import axios from "axios";

const DentalQuestion = ({ dental, setDental }) => {
  const [selectAll, setSelectAll] = useState(false);
  const [dentals, setDentals] = useState([]);
  const [isChecked, setIsChecked] = useState(
    Array(dentals ? dentals.length : 0).fill(false)
  );

  const getDentalQues = async () => {
    try {
      let res = await axios.get(
        `${process.env.REACT_APP_BACKEND}/dental-questions`
      );
      setDentals(res.data.data);
    } catch (error) {
      return error;
    }
  };
  useEffect(() => {
    getDentalQues();
  }, []);

  const checkboxHandler = (idx) => {
    const newArr = [...isChecked];
    if (newArr[idx]) {
      newArr[idx] = false;
    } else {
      newArr[idx] = true;
    }
    setIsChecked(newArr);
    let obj;
    for (let index = 0; index < newArr.length; index++) {
      if (newArr[index] === true) {
        obj = { dentalQuestionId: dentals[index].id };
        setDentalQuestionId(obj);
      }
    }
  };

  const setDentalQuestionId = (objId) => {
    setDental([...dental, objId]);
  };

  const selectAllHandler = (e) => {
    setSelectAll(e.target.checked);
    let all;

    all = dentals.map((item) => {
      return { dentalQuestionId: item.id };
    });
    if (e.target.checked === true) {
      setDental(all);
    } else if (e.target.checked === false) {
      setDental([]);
      setIsChecked([]);
    }
  };

  return (
    <>
      <h6 className="text-uppercase my-3 text-center">
        Professional Dental Interest
      </h6>

      <div className="control-group">
        <div className="check-all">
          <label className="control control-checkbox">
            Select All Options
            <input type="checkbox" onChange={selectAllHandler} />
            <div className="control_indicator"></div>
          </label>
        </div>
        <div className="custom-wrapper">
          {dentals.map((item, index) => (
            <div className="label-container" key={item.id}>
              <label className="control control-checkbox">
                {item.question}
                <input
                  type="checkbox"
                  checked={
                    selectAll ? "checked" : isChecked[index] ? "checked" : ""
                  }
                  onChange={() => {
                    checkboxHandler(index);
                  }}
                />
                <div className="control_indicator"></div>
              </label>
            </div>
          ))}
        </div>
      </div>
    </>
  );
};

export default DentalQuestion;
